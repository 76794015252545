<template>
  <div class="manage-users-form">
    <!-- Buttons -->
    <v-form novalidate @submit.prevent="onSubmit">
      <v-row>
        <v-col>
          <!-- name -->
          <v-text-field
            v-model="$v.formFields.name.$model"
            label="Nombre"
            type="text"
            outlined
            dense
            color="grey"
            :hide-details="!checkFieldErrors('name').length"
            :error-messages="checkFieldErrors('name')"
            @input="touchField('name')"
            @blur="touchField('name')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- email -->
          <v-text-field
            v-model="$v.formFields.email.$model"
            label="Email"
            type="text"
            outlined
            dense
            color="grey"
            :hide-details="!checkFieldErrors('email').length"
            :error-messages="checkFieldErrors('email')"
            @input="touchField('email')"
            @blur="touchField('email')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- password -->
          <v-text-field
            v-model="$v.formFields.password.$model"
            label="Contraseña"
            outlined
            dense
            color="grey"
            :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="togglePassword ? 'text' : 'password'"
            :hide-details="!checkFieldErrors('password').length"
            :error-messages="checkFieldErrors('password')"
            @input="touchField('password')"
            @blur="touchField('password')"
            @click:append="togglePassword = !togglePassword"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- Rol Selected -->
          <v-select
            v-model="$v.formFields.role.$model"
            :items="availableRoles"
            item-value="value"
            item-text="label"
            label="Rol"
            outlined
            dense
            color="grey"
            :hide-details="!checkFieldErrors('role').length"
            :error-messages="checkFieldErrors('role')"
            @input="touchField('role')"
            @blur="touchField('role')"
          />
        </v-col>
      </v-row>
      <!-- Form Buttons -->
      <FormButtons
        accept-button-text="Crear"
        :accept-button-loading="formProcessing"
        @onClickCancelButton="hideDialog"
      />
    </v-form>
  </div>
</template>

<script>
// Constants
import { MANAGE_USERS_ROLES } from '../constants'
import { ADDONS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ManageUsersForm',
  components: { FormButtons },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        name: null,
        email: null,
        password: null,
        role: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio'
        },
        role: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData']),
    /**
     * Devuelve la lista de roles formateada para v-select
     *
     * @return {Array}
     */
    availableRoles() {
      const roles = get(this.placeAddonsSetupByUser, `${ADDONS.manageUsers}.roles`, [])
      // Se obtiene la información de ese addon en las constantes
      return roles.reduce((sumRoles, role) => {
        if (MANAGE_USERS_ROLES[role]) {
          sumRoles.push(MANAGE_USERS_ROLES[role])
        }
        return sumRoles
      }, [])
    }
  },
  methods: {
    ...mapActions('place', ['addUserToPlace']),
    /**
     * Avento de formulario
     */
    async afterSubmit() {
      // Se llama a la api de crear nuevo empleado
      await this.addUserToPlace({
        brand: getEnvironmentVariable('VUE_APP_BRAND'),
        companies: { [this.companyData.id]: true },
        places: { [this.placeData.id]: true },
        ...this.formFields
      })

      // Show info
      this.modifyAppAlert({
        text: 'Empleado creado correctamente.',
        visible: true
      })

      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  validations: {
    formFields: {
      name: { required },
      email: { required, email },
      password: { required },
      role: { required }
    }
  }
}
</script>

// Constants
import { ADDONS, ROLES } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import ManageUsersForm from '../../components/ManageUsersForm'
import ManageUsersListItem from '../../components/ManageUsersListItem'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyList from '@/components/ui/VuetifyList'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions, mapState } from 'vuex'
// Services
import { getEveryUsersByIds } from '@/services/user'
// Utils
import { getPathImage } from '@/utils'
import { get } from 'lodash'

export default {
  name: 'ManageUsersList',
  components: {
    CardContainer,
    ManageUsersListItem,
    SectionInformationBox,
    VuetifyContentLoading,
    VuetifyList,
    VuetifyToolBar
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      items: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('authentication', ['userData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * ¿Ocultamos el botón de "acción"?
     *
     * @return {Boolean}
     */
    hideToolBarActionButton() {
      const maxUsers = get(this.placeAddonsSetupByUser, `${ADDONS.manageUsers}.maxUsers`, 3)
      const currentUsers = get(this.placeData, 'users', {})

      return maxUsers < Object.keys(currentUsers).length
    },
    /**
     * Get the options to show the screen when there are not items
     *
     * @return {Object} - options to component
     */
    sectionInformationOptions() {
      return {
        media: getPathImage('icons-horeca/place-team.svg'),
        title: 'Gestión de equipo',
        description: 'No hay ningún miembro creado',
        buttonLabel: 'Crear miembro equipo ¡ahora!'
      }
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  watch: {
    placeData: {
      handler(value) {
        if (value.users) {
          // Actualizamos la lista de empleados
          this.setItems()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('place', ['deleteUserFromPlace']),
    /**
     *  Se abre un modal con el formulario de creación de usuario
     */
    handleClickToolBarActionButton() {
      this.modifyAppDialog({
        title: 'Crear nuevo empleado',
        contentComponent: ManageUsersForm,
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Evento que se lanza cuando pulsamos sobre
     * el botón de borrado
     *
     * @param {Object} item - datos del item que se desea borrar
     */
    async handleClickButton(item) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            this.processingRequest = true
            // Se elimina un empleado y se desasocia de places y company
            await this.deleteUserFromPlace(item.id)
          } catch (error) {
            // show error
            this.handleError(error.message)
          } finally {
            this.processingRequest = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Estas seguro que quieres eliminar al usuario?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Se obtiene la lista de empleados del establecimiento
     */
    async setItems() {
      // Loading
      this.processingRequest = true
      try {
        // Eliminamos claves de usuarios
        const userKeys = Object.keys(this.placeData.users).filter(
          (item) => item.id != this.userData.id
        )
        // Obtenemos datos de los usuarios
        const users = await getEveryUsersByIds(userKeys)
        // Establecemos items
        this.items = users.filter((user) => user && user.role === ROLES.horeca_manager.value)
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    }
  }
}

<template>
  <v-list-item two-line class="manager-users-list-item">
    <v-list-item-content>
      <v-list-item-title class="primary--text">
        {{ item.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="grey--text">
        {{ labelRol }} / {{ item.email }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <!-- Buttons Delete  -->
      <v-btn fab small icon @click="handleClickButton(item)"
        ><v-icon color="red">mdi-close</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
// Constants
import { MANAGE_USERS_ROLES } from '../constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'ManageUsersListItem',
  mixins: [uiMixin],
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    /**
     * Obtenemos el rol del usuario (etiqueta)
     *
     * @return {string}
     */
    labelRol() {
      return this.item.role && MANAGE_USERS_ROLES[this.item.role]
        ? MANAGE_USERS_ROLES[this.item.role].label
        : ''
    }
  },
  methods: {
    /**
     * Manejador del evento del "click" del botón
     *
     * @param {Object} item
     */
    handleClickButton(item) {
      this.$emit('onClickButton', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.manager-users-list-item {
  background-color: #fff;
  margin-bottom: 1px solid $theme_border_color;
}
</style>
